import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React from 'react';

import { useQuestionnaireResponseWithToken } from '~/hooks/questionnaire-response';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { QuestionnaireResponseContent } from '~/screens/questionnaire-response/questionnaire-response';

type TokenAuthQuestionnaireResponseNavigation = StackNavigationProp<
    AppNavigatorParamList,
    'token-auth-questionnaire-response'
>;
type TokenAuthQuestionnaireResponseRoute = RouteProp<AppNavigatorParamList, 'token-auth-questionnaire-response'>;

export const TokenAuthQuestionnaireResponse: React.FC = () => {
    const { setOptions, navigate } = useNavigation<TokenAuthQuestionnaireResponseNavigation>();
    const {
        params: { token, batchId, memberId, responseId }
    } = useRoute<TokenAuthQuestionnaireResponseRoute>();

    const questionnaireResponseValues = useQuestionnaireResponseWithToken(batchId, memberId, token, responseId);

    const handleClose = () => {
        navigate('token-auth-questionnaire', { memberId, batchId, token });
    };

    return (
        <QuestionnaireResponseContent onClose={handleClose} setOptions={setOptions} {...questionnaireResponseValues} />
    );
};
