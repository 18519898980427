import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Flex } from 'native-base';
import React, { useCallback, useLayoutEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components/native';

import { ButtonSheet } from '~/components/button';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { LoadingContent } from '~/components/loader';
import { ScreenContainer } from '~/components/screen';
import { ContentScrollView } from '~/components/screen/content-scroll-view';
import { Spacing } from '~/components/spacing';
import { SystemMessage } from '~/components/system-message';
import { Text } from '~/components/text';
import { useIntl } from '~/contexts/intl';
import { QuestionnaireResponseBatchListItem, useQuestionnaireResponseBatchList } from '~/hooks/questionnaire-list';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

type QuestionnairesNavigation = StackNavigationProp<MainNavigatorParamList, 'questionnaires'>;

const NoContentContainer = styled.View`
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export function Questionnaires() {
    const { questionnaireResponseBatches, loading, error } = useQuestionnaireResponseBatchList();
    const { setOptions, navigate } = useNavigation<QuestionnairesNavigation>();

    const { formatMessage } = useIntl();
    useErrorHandler(error);

    useLayoutEffect(() => setOptions({ title: formatMessage('profile.questionnaires.title') }));

    const handlePress = useCallback(
        (questionnaireResponseId: ID) => {
            navigate('questionnaire-response', { questionnaireResponseId });
        },
        [navigate]
    );

    return (
        <QuestionnaireResponseBatchesList
            loading={loading}
            questionnaireResponseBatches={questionnaireResponseBatches}
            onPress={handlePress}
        />
    );
}

export type QuestionnaireResponseBatchesListProps = {
    loading: boolean;
    questionnaireResponseBatches?: QuestionnaireResponseBatchListItem[];
    onPress: (questionnaireResponseId: ID) => void;
};

export const QuestionnaireResponseBatchesList: React.FC<QuestionnaireResponseBatchesListProps> = ({
    loading,
    questionnaireResponseBatches,
    onPress
}) => {
    const { formatMessage } = useIntl();

    return (
        <ScreenContainer>
            {loading ? (
                <LoadingContent />
            ) : (
                <ContentScrollView
                    showsVerticalScrollIndicator={false}
                    bounces
                    scrollEventThrottle={16}
                    overScrollMode="never"
                >
                    <ContentHorizontalMargins contentWidth="default" includePadding={false}>
                        <Flex flexDir="column" alignSelf="stretch" alignItems="stretch" shrink="1" grow="1">
                            {questionnaireResponseBatches === undefined || questionnaireResponseBatches.length === 0 ? (
                                <NoContentContainer>
                                    <Spacing.Vertical.MASSIVE />
                                    <SystemMessage label={formatMessage('profile.questionnaires.none')} />
                                </NoContentContainer>
                            ) : (
                                questionnaireResponseBatches.map(batch => (
                                    <QuestionnaireResponseBatch key={batch.id} batch={batch} onPress={onPress} />
                                ))
                            )}
                        </Flex>
                    </ContentHorizontalMargins>
                </ContentScrollView>
            )}
        </ScreenContainer>
    );
};

export type QuestionnaireResponseBatchProps = {
    batch: QuestionnaireResponseBatchListItem;
    onPress: (questionnaireResponseId: ID) => void;
};

export const QuestionnaireResponseBatch = (props: QuestionnaireResponseBatchProps) => {
    const {
        batch: { description, openAt, closeAt, questionnaireResponses, openForResponses },
        onPress
    } = props;

    return (
        <Flex style={{ marginVertical: Spacing.MEDIUM }} alignSelf="stretch">
            <Flex mx="4">
                <Text.HEADER_2>
                    <FormattedMessage id="questionnaire-response-batch.title" values={{ date: openAt }} />
                </Text.HEADER_2>
                {description ? <Text.PARAGRAPH_1>{description}</Text.PARAGRAPH_1> : null}
                <Text.PARAGRAPH_1 before={Spacing.SMALL}>
                    {openForResponses ? (
                        <FormattedMessage id="questionnaire-response-batch.open-until" values={{ date: closeAt }} />
                    ) : (
                        <FormattedMessage id="questionnaire-response-batch.closed-at" values={{ date: closeAt }} />
                    )}
                </Text.PARAGRAPH_1>
            </Flex>
            <ButtonSheet
                leadingSeparator
                trailingSeparator
                carets
                style={{ marginVertical: Spacing.MEDIUM }}
                items={questionnaireResponses.map(questionnaireResponse => {
                    const { id, description: questionnaireResponseDescription, title } = questionnaireResponse;
                    return {
                        key: id,
                        label: questionnaireResponseDescription ?? '',
                        title,
                        onPress: () => onPress(id)
                    };
                })}
            />
        </Flex>
    );
};
