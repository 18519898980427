import { Flex } from 'native-base';
import React, { useCallback } from 'react';

import { BannerButton } from '~/components/button/banner-button';
import { MasonryList } from '~/components/masonry-list';
import { NoContentContainer } from '~/components/no-content-container';
import { Spacing } from '~/components/spacing';
import { SystemMessage } from '~/components/system-message';
import { useRichLink } from '~/hooks/linking/rich-link';
import { CallToActionFragment } from '~/types';

type Props = {
    callToActions: readonly CallToActionFragment[];
    noContentMessage?: string;
    gridCustomColumnCount?: number;
};

export const CallToActionList: React.FC<Props> = props => {
    const handleLink = useRichLink();

    const { callToActions, noContentMessage, gridCustomColumnCount } = props;

    const renderItem = useCallback(
        (item: CallToActionFragment) => {
            const { description, id, imageUrl, targetUrl, title } = item;

            return (
                <BannerButton
                    key={id}
                    imageSrc={{ uri: imageUrl }}
                    title={title}
                    description={description ?? undefined}
                    onPress={() => {
                        handleLink(targetUrl);
                    }}
                />
            );
        },
        [handleLink]
    );

    return (
        <>
            {callToActions.length > 0 ? (
                <MasonryList
                    items={callToActions}
                    renderItem={renderItem}
                    noContentMessage={noContentMessage}
                    customColumnCount={gridCustomColumnCount}
                />
            ) : (
                <Flex>
                    <NoContentContainer>
                        <Spacing.Vertical.SMALL />
                        <SystemMessage label={noContentMessage ?? '-'} />
                    </NoContentContainer>
                </Flex>
            )}
        </>
    );
};
