import { BottomTabHeaderProps, BottomTabNavigationProp } from '@react-navigation/bottom-tabs';
import { ParamListBase } from '@react-navigation/native';
import { StackHeaderProps, StackNavigationProp } from '@react-navigation/stack';
import { Flex } from 'native-base';
import React, { ReactNode, useCallback } from 'react';
import { Linking, Pressable } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { Color } from '~/components/color';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';
import { HorizontalLogo } from '~/components/horizontal-logo';
import { TabServicesIcon } from '~/components/icon';
import { Spacing } from '~/components/spacing';
import { useAppConfig } from '~/contexts/app-config';
import { useAuth } from '~/contexts/auth';
import { useUserProfile } from '~/contexts/user-profile';
import {
    TabBarCaptionBadgeContainer,
    tabBarIcon,
    TabBarItemBadge,
    TabBarItemContainer,
    TabBarItemFunction,
    tabBarLabel,
    tabNavigationScreens
} from '~/navigator/tab-navigation-config';
import { isFunction } from '~/utils';

type HeaderRight = {
    headerRight?: ReactNode;
};

export const HelttiHeaderBottomTabBarWrapper: React.FC<BottomTabHeaderProps> = ({ navigation, route }) => {
    return <HelttiHeader navigation={navigation} currentRoute={route.name} />;
};

export const HelttiHeaderStackHeaderWrapper: React.FC<StackHeaderProps & HeaderRight> = ({
    navigation,
    route,
    headerRight
}) => {
    return <HelttiHeader navigation={navigation} headerRight={headerRight} currentRoute={route.name} />;
};

type HelttiHeaderProps = {
    navigation: StackNavigationProp<ParamListBase> | BottomTabNavigationProp<ParamListBase>;
    currentRoute: string;
    headerRight?: ReactNode;
};

const HelttiHeader: React.FC<HelttiHeaderProps> = props => {
    const { navigation, currentRoute, headerRight } = props;
    const { top } = useSafeAreaInsets();
    const { state } = useAuth();

    const handleLogoPress = useCallback(() => {
        if (state === 'authenticated') {
            navigation.navigate('home');
        }
    }, [navigation, state]);

    return (
        <Flex
            backgroundColor={Color.BACKGROUND_DEFAULT}
            pt={top}
            borderBottomWidth="1"
            borderBottomColor={Color.BORDER_DEFAULT}
        >
            <ContentHorizontalMargins contentWidth="default">
                <Flex flexDirection="row" alignItems="center" flexGrow="1" justifyContent="space-between" pt="4" pb="2">
                    <Pressable testID="horizontal-logo-touchable" onPress={handleLogoPress}>
                        <HorizontalLogo />
                    </Pressable>
                    {headerRight ? (
                        headerRight
                    ) : (
                        <Flex flexDirection="row">
                            {tabNavigationScreens.map(item => {
                                const { name, options } = item;
                                const { tabBarIcon, tabBarLabel: TabBarLabel, tabBarBadge } = options;
                                const focused = name === currentRoute;

                                const handlePress = () => {
                                    navigation.navigate(name);
                                };

                                return (
                                    <Pressable
                                        key={name}
                                        accessibilityRole="button"
                                        accessibilityLabel={options.tabBarAccessibilityLabel}
                                        onPress={handlePress}
                                        style={{ flex: 1, alignItems: 'center', marginRight: Spacing.MEDIUM }}
                                    >
                                        <TabBarItemContainer /* TODO: layout={Layout.duration(300)}*/>
                                            {isFunction<TabBarItemFunction>(tabBarIcon) && tabBarIcon({ focused })}
                                            <TabBarCaptionBadgeContainer>
                                                {isFunction<TabBarItemFunction>(TabBarLabel) && (
                                                    <TabBarLabel focused={focused} />
                                                )}
                                                {tabBarBadge ? <TabBarItemBadge /> : null}
                                            </TabBarCaptionBadgeContainer>
                                        </TabBarItemContainer>
                                    </Pressable>
                                );
                            })}
                            <HelttiViewLink />
                        </Flex>
                    )}
                </Flex>
            </ContentHorizontalMargins>
        </Flex>
    );
};

// TODO: Temporary solution for HelttiView link until external url support in CTA list
// is deployed to native app also.
export const HelttiViewLink = () => {
    const { hasCompanyAccess } = useUserProfile();
    const {
        config: { helttiViewUrl }
    } = useAppConfig();

    if (!hasCompanyAccess) {
        return null;
    }

    const handlePress = () => {
        Linking.openURL(helttiViewUrl);
    };

    return (
        <Pressable
            key="heltti-view"
            accessibilityRole="button"
            accessibilityLabel="HelttiView"
            onPress={handlePress}
            style={{ flex: 1, alignItems: 'center', marginRight: Spacing.MEDIUM }}
        >
            <TabBarItemContainer>
                {tabBarIcon(TabServicesIcon)({ focused: false })}
                <TabBarCaptionBadgeContainer>
                    {tabBarLabel('tab-bar.helttiview')({ focused: false })}
                </TabBarCaptionBadgeContainer>
            </TabBarItemContainer>
        </Pressable>
    );
};
