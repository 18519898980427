import { StackNavigationOptions } from '@react-navigation/stack/lib/typescript/src/types';
import { Flex } from 'native-base';
import React from 'react';
import { ModalProps, Platform } from 'react-native';
import { ContentHorizontalMargins } from '~/components/content-horizontal-margins/content-horizontal-margins';

import { HeaderBackButton, styles } from '~/components/header';
import { HorizontalLogo } from '~/components/horizontal-logo';

export const fadeCardStyle = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardStyleInterpolator: ({ current }: any) => ({
        cardStyle: {
            opacity: current.progress
        }
    })
};

export const noHeader = {
    headerShown: false,
    title: ''
};

export const header: StackNavigationOptions = {
    headerShown: true,
    headerTitleAlign: 'left',
    headerTitleStyle: styles.headerTitle,
    headerLeftContainerStyle: styles.headerLeft,
    headerRightContainerStyle: styles.headerRight,
    headerBackTitleVisible: false,
    headerLeft: () => <HeaderBackButton />
};

export const logoHeader: StackNavigationOptions = {
    headerShown: true,
    headerLeftContainerStyle: styles.headerLeft,
    headerLeft: () => (
        <Flex grow={1} direction="row">
            <ContentHorizontalMargins contentWidth="default">
                <Flex flexDirection="row" alignItems="center" flexGrow="1" justifyContent="space-between" pt="4" pb="2">
                    <HorizontalLogo />
                </Flex>
            </ContentHorizontalMargins>
        </Flex>
    ),
    title: ''
};

export const transparentHeader: StackNavigationOptions = {
    ...header,
    headerTransparent: true
};

export const transparentHeaderWithoutTitle: StackNavigationOptions = {
    ...header,
    headerTransparent: true,
    title: ''
};

export const transparentHeaderWithoutTitleOrBack: StackNavigationOptions = {
    ...header,
    headerLeft: () => <></>,
    headerRight: () => <></>,
    headerTransparent: true,
    title: ''
};

export const modal = Platform.select<ModalProps>({
    ios: { animationType: 'slide', presentationStyle: 'formSheet', hardwareAccelerated: true },
    web: { animationType: 'fade', hardwareAccelerated: true }
});
