import { RouteProp, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useLayoutEffect } from 'react';

import { useIntl } from '~/contexts/intl';
import { useRoute } from '~/hooks/route/route';
import { MainNavigatorParamList } from '~/navigator/main-navigator';

import '@whereby.com/browser-sdk/embed';

type VideoCallNavigation = StackNavigationProp<MainNavigatorParamList, 'appointment'>;

export type VideoCallRouteProp = RouteProp<MainNavigatorParamList, 'video-call'>;

export function VideoCall() {
    const { setOptions } = useNavigation<VideoCallNavigation>();
    const { params } = useRoute<VideoCallRouteProp>();
    const { formatMessage } = useIntl();

    const roomUrl = params.url;

    useLayoutEffect(() => {
        setOptions({
            headerTitle: formatMessage('video-call.title')
        });
    });

    return <whereby-embed room={roomUrl} background="off" style={{ flexGrow: '1' }} />;
}
