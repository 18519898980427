import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { useBreakpointValue } from 'native-base';
import React, { useCallback } from 'react';

import HeroImage from '~/assets/images/shining-heart.svg';
import { Button } from '~/components/button';
import { EllipticHeaderScreen } from '~/components/screen';
import { Spacing } from '~/components/spacing';
import { Text } from '~/components/text';
import { FormattedMessage, useIntl } from '~/contexts/intl';
import { useQuestionnaireResponseBatchListWithToken } from '~/hooks/questionnaire-list';
import { AppNavigatorParamList } from '~/navigator/app-navigator';
import { QuestionnaireResponseBatchesList } from '~/screens/questionnaires';

type TokenAuthQuestionnaireNavigation = StackNavigationProp<AppNavigatorParamList, 'token-auth-questionnaire'>;
type TokenAuthQuestionnaireRoute = RouteProp<AppNavigatorParamList, 'token-auth-questionnaire'>;

export const TokenAuthQuestionnaire: React.FC = () => {
    const { navigate } = useNavigation<TokenAuthQuestionnaireNavigation>();
    const {
        params: { token, batchId, memberId }
    } = useRoute<TokenAuthQuestionnaireRoute>();

    const { formatMessage } = useIntl();

    const { questionnaireResponseBatches, loading, error } = useQuestionnaireResponseBatchListWithToken({
        token,
        batchId,
        memberId
    });

    const handleLoginPress = () => {
        navigate('verification-check');
    };

    const shouldRenderTopContent: boolean = useBreakpointValue({ base: false, md: true });

    const handlePress = useCallback(
        (questionnaireResponseId: ID) => {
            navigate('token-auth-questionnaire-response', {
                memberId,
                token,
                batchId,
                responseId: questionnaireResponseId
            });
        },
        [batchId, memberId, navigate, token]
    );

    if (error) {
        return (
            <EllipticHeaderScreen
                renderEllipticMask
                renderTopContent={shouldRenderTopContent ? () => <HeroImage /> : undefined}
                renderBottomContent={() => (
                    <>
                        <Text.HEADER_1 after={Spacing.LARGE}>
                            <FormattedMessage id="token-auth-questionnaire.not-found.title" />
                        </Text.HEADER_1>
                        <Text.PARAGRAPH_1>
                            <FormattedMessage id="token-auth-questionnaire.not-found.description" />
                        </Text.PARAGRAPH_1>
                    </>
                )}
                renderActions={() => (
                    <Button
                        type="primary"
                        onPress={handleLoginPress}
                        label={formatMessage('token-auth-questionnaire.not-found.button-label')}
                    />
                )}
            />
        );
    }

    return (
        <QuestionnaireResponseBatchesList
            loading={loading}
            questionnaireResponseBatches={questionnaireResponseBatches}
            onPress={handlePress}
        />
    );
};
